import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 订单分组 - 创建一条
export function orderGroupCreate(data) {
  return request({
    url: '/externaladmin/orderService/importGroup/create',
    method: 'post',
    data
  })
}

// 订单分组 - 更新一条
export function orderGroupUpdate(data) {
  return request({
    url: '/externaladmin/orderService/importGroup/update',
    method: 'post',
    data
  })
}

// 订单 - 批量创建数据记录
export function orderBatchCreate(data) {
  return request({
    url: '/externaladmin/orderService/order/batchCreate',
    method: 'post',
    data
  })
}

// 订单 - 批量更新数据记录
export function orderBatchUpdate(data) {
  return request({
    url: '/externaladmin/orderService/order/batchUpdate',
    method: 'post',
    data
  })
}

// 订单 - 更新一条
export function orderUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/updateStatus',
    method: 'post',
    data
  })
}

// 订单 - 复杂更新
export function orderComplexUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrder/complexUpdate',
    method: 'post',
    data
  })
}

// 订单子项 - 更新一条
export function orderItemUpdate(data) {
  return request({
    url: '/externaladmin/orderService/externalOrderItem/relationEdit',
    method: 'post',
    data
  })
}

// 同步刀版图
export function knifeList(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customSpecificProduct/listByOrderId',
    method: 'post',
    data
  })
}

// 同步刀版图
export function syncKnifeLayoutPath(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/orderItem/syncKnifeLayoutPath',
    method: 'post',
    data
  })
}
// 更新刀版图
export function updateKnifeLayoutPath(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customSpecificProduct/updateKnifeLayoutPath',
    method: 'post',
    data
  })
}

// 订单 - 列表
export function orderList(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrder/orderList',
    method: 'post',
    data
  })
}

// 订单子项 - 列表
export function orderItemList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrderItem/orderItemList',
    method: 'post',
    data
  })
}

// 订单子项 - 批量添加
export function orderItembatchUpdate(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/batchUpdate',
    method: 'post',
    data
  })
}

// 订单子项 - 添加
export function orderItemCreate(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/relationEdit',
    method: 'post',
    data
  })
}

// 订单子项 - 删除一条
export function orderItemDel(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/delete',
    method: 'post',
    data
  })
}

// 截单批次 - 订单导出
export function exportCutoffBatch(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportCutoffBatch',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 排单批次 - 订单导出
export function exportSchedul(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportSchedul',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 报关清单 - 订单导出
export function exportForCustoms(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportForCustoms',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 订单导出
export function exportForCustomsAll(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportAll',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// 订单导出 - 未关联订单导出
export function exportNotRelation(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportNotRelation',
    method: 'post',
    responseType: 'blob',
    data
  })
}

<template>
  <el-dialog v-el-drag-dialog title="刀版图查看" :visible.sync="dialogVisible" width="800px" top="8vh">
    <div class="flex-between">
      <div>
        <checkAllData
          ref="checkAllData"
          :tableData="tableData"
          :page="tablePage.pageIndex"
          :size="tablePage.pageSize"
          :total="tablePage.total"
          :isPageChange="isPageChange"
          :setIsPageChangeFalse="setIsPageChangeFalse"
          :getAllData="getAllData"
          :selectionData="selectionData"
          :selectable="tableOption.selectable"
          :getElTableInstance="getElTableInstance"
          @checkAllStatus="checkAllStatusChange"
        ></checkAllData>
        <loadingBtn class="ml10" type="primary" size="small" @click="updateKnifeLayout">更新刀版图</loadingBtn>
      </div>
      <avue-crud-select
        v-model="resetMergeData.isKnifeLayoutCreated"
        :dic="dicData"
        size="small"
        style="width: 200px"
        @change="searchChange"
      ></avue-crud-select>
    </div>
    <avue-crud
      ref="crud"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @selection-change="handleSelectionChange"
    >
      <template #knifeLayoutImagePath="{ row }">
        <defaultImg
          :src="row.knifeLayoutImagePath"
          :previewSrcList="[row.knifeLayoutImagePath]"
          disabled
          size="min"
          width="80"
          height="80"
        ></defaultImg>
      </template>
      <template #menu="{ row }">
        <loadingBtn class="menu-btn-item" type="text" @click="updateKnifeLayout('item', row)">更新刀版图</loadingBtn>
      </template>
    </avue-crud>
  </el-dialog>
</template>

<script>
import { avueCrud, dialogComponentsMixin } from '@/mixins'
import checkAllData from '@/views/components/checkAllData3'
import defaultImg from '@/views/components/defaultImg'
import { getValueFromObj } from '@/utils'
import { knifeList as getList, updateKnifeLayoutPath } from '@/api/order'

let tableOption = {
  selection: true,
  menuWidth: 188,
  editBtn: false,
  delBtn: false,
  topPage: false,
  search: false,
  column: [
    {
      label: '自定义SKU',
      prop: 'customSkuCode'
    },
    {
      label: '内部SKU',
      prop: 'sku'
    },
    {
      label: '款式',
      prop: 'styleName'
    },
    {
      label: '刀版图状态',
      prop: 'isKnifeLayoutCreated',
      formatter(row) {
        return row.knifeLayoutImagePath ? '已生成' : '未生成'
      }
    },
    {
      label: '刀版图',
      prop: 'knifeLayoutImagePath',
      slot: true
    }
  ]
}
export default {
  components: {
    defaultImg,
    checkAllData
  },
  mixins: [
    avueCrud({
      tableOption,
      getList,
      isInit: false
    }),
    dialogComponentsMixin
  ],
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      resetMergeData: {},
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      },
      dicData: [
        {
          label: '未生成刀版图',
          value: 0
        },
        {
          label: '已生成刀版图',
          value: 1
        }
      ],

      // 全选必加
      // selectionData: [],
      selectionDataAllArr: []
    }
  },
  watch: {
    dialogVisible: {
      handler(n) {
        if (n) {
          let { resetMergeData, data } = this
          if (resetMergeData.orderId !== data.id) {
            this.tableData = []
          }
          resetMergeData.orderId = data.id
          this.searchChange()
        }
      },
      immediate: true
    }
  },
  methods: {
    async updateKnifeLayout(type, row) {
      switch (type) {
        case 'item':
          row = Array.isArray(row) ? row : [row]
          break
        default:
          if (this.selectionDataAllArr.length === 0) {
            return this.$message.warning('请先选择产品')
          }
          row = await this.getSelectionDataAllArr()
          break
      }
      let res = await awaitResolve(
        updateKnifeLayoutPath({
          updateKnifeLayoutPathDTOList: row.map((item) => ({
            knifeLayoutId: item.knifeLayoutId,
            specificProductId: item.specificProductId
          }))
        })
      )
      if (!res) return this.$message.warning('更新刀版图失败')
      this.$message.success('更新刀版图成功')
      await this.refreshPage()
    },

    // 全选必加
    afterInit(res, postData) {
      let initCallBack = getValueFromObj(this.$refs.checkAllData, 'initCallBack')
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
    },
    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
      // console.log(this.selectionDataAllArr)
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.checkAllData.getSelectionDataAllArr()
      this.selectionDataAllArr = tempArr.map(({ id }) => id)
      // console.log(tempArr)
      return tempArr
    },
    async getAllData() {
      let res = await awaitResolve(
        getList({
          ...this.postData,
          isReturnRelatedData: 0,
          isReturnWaybillChangeRecord: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      if (!res) return []
      return res.detail || []
    },
    getElTableInstance() {
      return getValueFromObj(this.$refs, 'crud.$refs.table')
    }
  }
}
</script>

<style lang="scss"></style>
